import MicroModal from 'micromodal';
import Splide from '@splidejs/splide';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", (event) => {
  MicroModal.init();

  const benlist = document.querySelector('.main-opportunities-benefits')
  const benmedia = new Splide( '.main-opportunities-benefits-media',
    {
      type: 'fade',
      perPage: 1,
      rewind: true,
      arrows: false,
      pagination: false,
      autoplay: true,
      
    }
  ).mount();

  new Splide( '.main-partners-slider__slider',
    {
      type     : 'loop',
      autoWidth: true,
      arrows: false,
      pagination: false
    }
  ).mount();
  
  

  
  


  document.querySelectorAll('.main-opportunities-benefits__content').forEach(function(elm, idx) {
    gsap.set(elm, { height: "auto"})
    if (idx != 0){ 
      gsap.to(elm, { 
        height: 0, 
        duration: 0.5, 
        ease: "power1.inOut"
      });
    }
    

  });
  
  benlist.querySelectorAll('.main-opportunities-benefits__list-item').forEach(function(elm, idx) {
    elm.addEventListener("click", (e) => {
      benmedia.go( idx );
    });

    elm.addEventListener("mouseenter", (e) => {
      if(elm.classList.contains("main-opportunities-benefits__list-item--active")) {
        var Autoplay = benmedia.Components.Autoplay;
        Autoplay.pause();
      }

    });
    
    elm.addEventListener("mouseleave", (e) => {
      if(elm.classList.contains("main-opportunities-benefits__list-item--active")) {
        var Autoplay = benmedia.Components.Autoplay;
        Autoplay.play();
      }
      
    });
  
    
  });

  benmedia.on( 'autoplay:playing', function ( rate ) {
    document.querySelectorAll('.main-opportunities-benefits-progress__line').forEach(function(progress) {
        progress.style.width = Math.round(rate * 100) + '%'
    });
    
  });
  benmedia.on( 'move', function ( newIndex, prevIndex) {
    const prevSlide = document.querySelectorAll('.main-opportunities-benefits__list-item')[prevIndex]
    prevSlide.classList.remove("main-opportunities-benefits__list-item--active");
    const prevSlideContent = prevSlide.querySelector('.main-opportunities-benefits__content')
    
    const nextSlide = document.querySelectorAll('.main-opportunities-benefits__list-item')[newIndex]
    nextSlide.classList.add("main-opportunities-benefits__list-item--active");
    const nextSlideContent = nextSlide.querySelector('.main-opportunities-benefits__content')
  
    // Keep a reference to the animation on the menu item itself
    gsap.to(prevSlideContent, { 
      height: 0, 
      duration: 0.5, 
      ease: "power1.inOut",
    });
    
    gsap.to(nextSlideContent, { 
      height: 'auto', 
      duration: 0.5, 
      ease: "power1.outIn",
    });
  });

  


  const words = gsap.utils.toArray(".hero-mainheader__word");
  const tl = gsap.timeline({
    repeat: -1
  });
  gsap.set(words, {
    yPercent: (i) => i && 100,
    opacity: 1
  });
  words.forEach((word, i) => {
    const next = words[i + 1];
    if (next) {
      tl.to(word, { yPercent: -100, opacity:0 }, "+=1.5").to(next, { yPercent: 0, opacity:1 }, "<");
    } else {
      // Final word
      tl.to(word, { yPercent: -100, opacity:0 }, "+=1.5").fromTo(
        words[0],
        {
          yPercent: 100,
          opacity:1
        },
        {
          opacity:1,
          yPercent: 1,
          immediateRender: false
        },
        "<"
      );
    }
  });
  let mm = gsap.matchMedia();

  mm.add("(min-width: 768px)", () => {
    const revItems = gsap.utils.toArray('.main-revolution-payments__list-item');

    revItems.forEach((section, idx) => {
      
      var endAnim = "bottom top";
      if (idx === revItems.length - 1){ 
        endAnim = "bottom bottom";
      }

      gsap.to(section, { 
        scrollTrigger: {
            trigger: section,
            pin: true,
            start: 'top top',
            end: endAnim,
            scrub: 1,
            ease: 'linear',
          }
      });
        
    })
  });

});